import { useState, Component } from 'react';
import {
    Navbar,
    Container,
    Nav,
    NavDropdown,
    Modal,
    Button,
    Form,
    FloatingLabel
} from 'react-bootstrap';

import { NotificationContainer, NotificationManager } from 'react-notifications';

import axios from 'axios';
import validate from 'validate.js';

import config from '../../../config/core';

import { Navigate } from 'react-router-dom';

import logo from '../../assets/vector/logo.svg';
import jwt_decode from 'jwt-decode';
import cookieman from '../../common/cookieman';
import isValidSession from '../../common/isValidSession';

class Header extends Component {
    state = {
        redirect: null,
        showInvitarClienteModal: false,

        nombre: {
            value: '',
            hasError: false,
            error: ''
        },
        primer_apellido: {
            value: '',
            hasError: false,
            error: ''
        },
        segundo_apellido: {
            value: '',
            hasError: false,
            error: ''
        },
        correo: {
            value: '',
            hasError: false,
            error: ''
        },
        telefono: {
            value: '',
            hasError: false,
            error: ''
        },
        membresia_seleccionada: {
            value: '',
            hasError: false,
            error: ''
        },
    };

    formConstraints = {
        nombre: {
            presence: {
                allowEmpty: false,
                message: 'es requerido'
            },
            format: {
                pattern: "[a-zA-ZÁÉÍÓÚáéíóúñÑ ]*",
                message: "solo puede contener letras"
            }
        },
        primer_apellido: {
            presence: {
                allowEmpty: false,
                message: 'es requerido'
            },
            format: {
                pattern: "[a-zA-ZÁÉÍÓÚáéíóúñÑ ]*",
                message: "solo puede contener letras"
            }
        },
        segundo_apellido: {
            presence: {
                allowEmpty: false,
                message: 'es requerido'
            },
            format: {
                allowEmpty: false,
                pattern: "[a-zA-ZÁÉÍÓÚáéíóúñÑ ]*",
                message: "solo puede contener letras"
            }
        },
        correo: {
            presence: {
                allowEmpty: false,
                message: 'es requerido'
            },
            email: true
        },
        telefono: {
            presence: {
                allowEmpty: false,
                message: 'es requerido'
            },
            format: {
                pattern: "[0-9]*",
                message: "solo puede contener números"
            }
        },
    };


    componentDidMount() {

        if (this.props.mustHaveSession) {
            isValidSession().then(isValid => {
                if (!isValid && this.state.redirect == null) {
                    this.setState({
                        ...this.state,
                        redirect: <Navigate to="/" />
                    });
                }
            });
        }
    }


    render() {
        let session = null;

        if (cookieman.checkItem('token') !== null) {
            session = jwt_decode(cookieman.getItem('token'));
        }

        return (
            <>
                {this.state.redirect}
                <Navbar expand="lg" >
                    <Container>
                        <Navbar.Brand href="/">
                            <img src={logo} />
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="me-auto">
                                {
                                    session !== null ?
                                        <Nav.Link href="/home">Home</Nav.Link>
                                        :
                                        null
                                }
                                {
                                    session !== null ?
                                        <Nav.Link href="/membresias">Membresías</Nav.Link>
                                        :
                                        null
                                }
                                {
                                    session !== null ?
                                        <NavDropdown menuVariant='dark' title="Clientes" id="basic-nav-dropdown">
                                            <NavDropdown.Item onClick={() => {
                                                this.openModal();
                                            }}>Invitar un cliente</NavDropdown.Item>
                                            <NavDropdown.Item href="/membresia/leer_qr">Leer QR</NavDropdown.Item>
                                        </NavDropdown>
                                        :
                                        null
                                }
                            </Nav>
                            {
                                session != null ?
                                    (
                                        <NavDropdown menuVariant="dark" title={session.nombre + ' ' + session.primer_apellido} id="basic-nav-dropdown" className="d-flex">
                                            <NavDropdown.Item href="/configuracion">Configuración</NavDropdown.Item>
                                            <NavDropdown.Divider />
                                            <NavDropdown.Item href="#" onClick={() => {
                                                this.setState({
                                                    ...this.state,
                                                    redirect: <Navigate to="/logout" />
                                                });
                                            }}>Salir</NavDropdown.Item>
                                        </NavDropdown>
                                    )
                                    :
                                    (
                                        <NavDropdown title="Cuenta" id="basic-nav-dropdown" className="d-flex">
                                            <NavDropdown.Item href="login">Iniciar Sesión</NavDropdown.Item>
                                        </NavDropdown>
                                    )
                            }
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
                

                <NotificationContainer />
            </>
        );
    }
}

export default Header;