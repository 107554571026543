import {
    Row,
    Col,
    Container
} from 'react-bootstrap';

import colors from '../common/colors';

import Header from './header';
import Footer from '../Layout/default/footer';
import Menu from './menu';

import ContainerAutoHeight from '../common/ContainerAutoHeight';

const Condiciones = () => {
    return (
        <>
            <Header />
            <ContainerAutoHeight style={{ padding: 0 }}>
                <Row>
                    <Col>
                        <h2>
                            <span style={{ color: colors.blue2 }}>Condiciones de Servicio</span>
                        </h2>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Row id="Introduccion">
                            <Col>
                                <p>Fecha de entrada en vigor: 1 de diciembre del 2022</p>
                            </Col>
                        </Row>
                        <Row id="TuRelacionConSuperks">
                            <Col>
                                <ol>
                                    <li>El dueño del negocio se compromete a proporcionar los beneficios estipulados de las membresías creadas en el sistema a sus clientes.</li>

                                    <li>El dueño del negocio se compromete a cumplir con los descuentos prometidos a los clientes con membresías válidas.</li>

                                    <li>El dueño del negocio se reserva el derecho de cambiar los descuentos y el período de tiempo de validez de las membresías en cualquier momento sin previo aviso.</li>

                                    <li>El dueño del negocio se compromete a proteger la privacidad de los datos de los clientes y a cumplir con todas las leyes y regulaciones aplicables.</li>

                                    <li>El dueño del negocio se compromete a utilizar las comunicaciones promocionales y de marketing relacionadas con el sistema de membresías de manera ética y respetuosa con los clientes.</li>

                                    <li>Estos términos y condiciones están sujetos a cambios sin previo aviso y es responsabilidad del dueño del negocio revisarlos periódicamente.</li>

                                    <li>Al usar Superks, el dueño del negocio acepta estos términos y condiciones y se compromete a cumplirlos.</li>
                                </ol>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </ContainerAutoHeight>
            <Footer />
        </>
    );
};

export default Condiciones;