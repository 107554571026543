import React, { Component } from 'react';

import config from '../../config/core';

import cookieman from '../common/cookieman';


import { Navigate } from 'react-router-dom';


export default () => {
  //cookieman.deleteItem('token');
  
  return (<Navigate to="/" />);
};