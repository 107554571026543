import { Component } from 'react';

import {
    Container,
    Row,
    Col
} from 'react-bootstrap';

import logo from '../../assets/vector/logo.svg';

import { Navigate } from 'react-router-dom';

import isValidSession from '../../common/isValidSession';

class Header extends Component {
    state = {
        redirect: null
    }

    componentDidMount() {

        if (this.props.mustHaveSession) {
            isValidSession().then(isValid => {
                console.log('isValidSession', isValid);
                if (!isValid && this.state.redirect == null) {
                    this.setState({
                        ...this.state,
                        redirect: <Navigate to="/" />
                    });
                }
            });
        }
    }

    render() {
        return (
            <>
                {this.state.redirect}
                <Container className="d-block d-md-none d-lg-none">
                    <Row>
                        <Col className="text-center" style={{
                            padding: '15px'
                        }}>
                            <a href="/home"><img src={logo} /></a>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    };
}

export default Header;