import React, { Component } from 'react';

import {
    Container,
    Row,
    Col,
    Card,
    Form,
    Button,
    FloatingLabel,
    Alert
} from 'react-bootstrap';

import BlueButton from '../common/Form/BlueButton';
import WhiteButton from '../common/Form/WhiteButton';

import axios from 'axios';

import config from '../../config/core';
import colors from '../common/colors'

import cookieman from '../common/cookieman';
import loadingOverlay from '../common/loadingOverlay';
import { Navigate } from 'react-router-dom';

import Header from '../Layout/default/header';
import Footer from '../Layout/default/footer';
import ContainerAutoHeight from '../common/ContainerAutoHeight';

import validate from 'validate.js';

import { NotificationContainer, NotificationManager } from 'react-notifications';
import isValidSession from '../common/isValidSession';


class Login extends Component {

    state = {
        email: {
            value: '',
            hasError: false,
            error: ''
        },
        password: {
            value: '',
            hasError: false,
            error: ''
        },
        verification_code: {
            value: '',
            hasError: false,
            error: ''
        },
        loading: true,
        loading_confirmacion: false,
        error: false,
        step: 1,
        redirect: null
    }

    formConstraints = {
        email: {
            presence: {
                allowEmpty: false,
                message: 'es requerido'
            },
            email: {
                message: 'debe ser una dirección de correo'
            }
        },
        password: {
            presence: {
                allowEmpty: false,
                message: 'es requerido'
            },
            format: {
                allowEmpty: false,
                pattern: "[a-zA-ZÁÉÍÓÚáéíóúñÑ0-9 ]*",
                message: "solo puede contener letras"
            }
        },
        verification_code: {
            presence: {
                allowEmpty: false,
                message: 'es requerido'
            },
            format: {
                pattern: "[0-9]*",
                message: "solo puede contener números",
            },
        },
    };

    componentDidMount() {
        isValidSession().then(isValid => {
            if (isValid) {
                this.setState({
                    ...this.state,
                    redirect: <Navigate to="/home" />
                });
            }
        });

        this.setState({
            ...this.state,
            loading: false
        });
    }

    validateField = (field) => {
        const constraint = {};

        constraint[field] = this.formConstraints[field];

        const data = {};

        data[field] = this.state[field].value;

        const validacion = validate(data, constraint);

        const newState = { ...this.state };

        if (typeof validacion !== 'undefined') {

            newState[field].hasError = true;
            newState[field].error = (
                <ul>
                    {
                        validacion[field].map(error => {
                            return (<li>{error}</li>);
                        })
                    }
                </ul>
            );

        }
        else {
            newState[field].hasError = false;
            newState[field].error = null;

        }
        this.setState(newState);

        return validacion;
    }

    setField(field, value) {
        const newState = { ...this.state };

        if (typeof newState[field].value !== 'undefined') {
            newState[field].value = value;

            this.setState(newState);
        }
    }

    setError(field, msg) {
        const newState = { ...this.state };

        if (typeof newState[field].error !== 'undefined') {
            newState[field].hasError = true;
            newState[field].error = msg;

            this.setState(newState);
        }
    }

    freeError(field) {
        const newState = { ...this.state };

        if (typeof newState[field].hasError !== 'undefined') {
            newState[field].hasError = false;

            this.setState(newState);
        }
    }

    setLoading(state) {
        this.setState({
            ...this.state,
            loading: state
        })
    }

    sendLogin = () => {

        if (typeof this.validateField('email') !== 'undefined' || typeof this.validateField('password') != 'undefined') {
            return false;
        }

        this.setLoading(true);

        const url = process.env.REACT_APP_BACKEND + '/usuarios/login';

        axios.post(url, {
            email: this.state.email.value,
            passwrd: this.state.password.value
        }, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'app': config.app
            }
        }).then(response => {

            this.setLoading(false);
            if (response.data.success) {
                const newState = { ...this.state };

                this.setState({
                    ...newState,
                    step: 2,
                    loading_confirmacion: false
                });
            }
            else {
                NotificationManager.error('Usuario o contraseña incorrectos', 'Error')
            }
        }).catch(error => {
            this.setLoading(false);
        });
    }

    sendLoginConfirmation = () => {

        if (typeof this.validateField('verification_code') !== 'undefined') {
            return false;
        }

        this.setLoading(true);

        const url = process.env.REACT_APP_BACKEND + '/usuarios/confirm_login';

        axios.post(url, {
            email: this.state.email.value,
            passwrd: this.state.password.value,
            verification_code: this.state.verification_code.value
        }, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'app': config.app
            }
        }).then(response => {
            if (response.data.success) {
                cookieman.setItem('token', response.data.data);
                this.setState({
                    ...this.state,
                    redirect: <Navigate to="/home" />
                });
            }
            else {
                NotificationManager.error(response.data.msg, 'Error');
                this.setLoading(false);
            }
        }).catch(error => {
            this.setLoading(false);
        });
    }

    solicitarRecuperacion = () => {
        this.setLoading(true);

        const url = process.env.REACT_APP_BACKEND + '/usuarios/restablecer_contrasena';

        axios.post(url, {
            correo: this.state.email.value,
        }, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'app': config.app
            }
        }).then(response => {
            NotificationManager.success('Te enviamos un correo para recuperar tu contraseña');
            this.setState({
                ...this.state,
                step: 4
            });
        }).catch(error => {
            NotificationManager.error(error.response.data.msg, 'Error');
        }).finally(_ => {
            this.setLoading(false);
        });
    }

    render() {
        const card = {
            border: 0,
            backgroundColor: colors.grey3,
        };

        const login_form = (
            <ContainerAutoHeight id="Step1">
                <Row className="justify-content-md-center">
                    <Col>
                        <h1 className="text-center">&nbsp;</h1>
                    </Col>
                </Row>
                <Row className="justify-content-md-center">
                    <Col lg={5} sm={12}>
                        <Card className="m1" style={card}>
                            <Card.Title className="mt-3 mb-3">
                                <h2 className="text-center">Inicia</h2>
                                <h1 className="text-center" style={{color: colors.blue2}}>Sesión</h1>
                            </Card.Title>
                            <Card.Body>
                                <Form action='login' onSubmit={(e) => {
                                    e.preventDefault();
                                }}>
                                    <Form.Group className="mb-3" controlId="FormCorreo">
                                        <FloatingLabel controlId="Correo" label="Correo" className="mb-3">
                                            <Form.Control
                                                isInvalid={this.state.email.hasError}
                                                type="email"
                                                disabled={this.loading}
                                                placeholder="nombre@ejemplo.com"
                                                onChange={e => { this.setField('email', e.target.value); }}
                                                onBlur={e => { this.validateField('email'); }} />
                                            <Form.Control.Feedback type="invalid">{this.state.email.error}</Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="FormPassword">
                                        <FloatingLabel controlId="Password" label="Password">
                                            <Form.Control
                                                isInvalid={this.state.password.hasError}
                                                type="password"
                                                disabled={this.loading}
                                                placeholder="Contraseña"
                                                onChange={e => { this.setField('password', e.target.value); }}
                                                onBlur={e => { this.validateField('password'); }} />
                                            <Form.Control.Feedback type="invalid">{this.state.password.error}</Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Form.Group>
                                    <div className="d-grid gap-2">
                                        <BlueButton variant="primary" disabled={this.loading} type="submit" size="lg" onClick={() => {
                                            this.sendLogin(this.state.email, this.state.password);
                                        }}>
                                            {this.loading ? 'Enviando' : 'Iniciar Sesión'}
                                        </BlueButton>

                                        <a href="#" className="text-center" onClick={e => {
                                            this.setState({
                                                ...this.state,
                                                step: 3
                                            })
                                        }}>¿Olvidaste tu contraseña?</a>
                                    </div>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </ContainerAutoHeight>
        );

        const confirm_login_form = (
            <ContainerAutoHeight id="Step2">
                <Row className="justify-content-md-center">
                    <Col>
                        <h1 className="text-center">&nbsp;</h1>
                    </Col>
                </Row>
                <Row className="justify-content-md-center">
                    <Col lg={5} sm={12}>
                        <Card className="m1" style={card}>
                            <Card.Title className="mt-3 mb-3">
                                <h2 className="text-center">Código de</h2>
                                <h1 className="text-center" style={{color: colors.blue2}}>confirmación</h1>
                            </Card.Title>
                            <Card.Body>
                                <p>
                                    Hemos enviado un código de confirmación a {this.state.email.value}. Ingrésalo a continuación para continuar:
                                </p>
                                <p>¿No recibiste tu código? <a onClick={() => {
                                    this.setField('loading_confirmacion', true);
                                    this.sendLogin(this.state.email, this.state.password);
                                }}>Enviar nuevamente</a></p>
                                <Form action='login' onSubmit={(e) => {
                                    e.preventDefault();
                                }}>
                                    <Form.Group className="mb-3" controlId="FormVerificationCode">
                                        <FloatingLabel controlId="VerificationCode" label="Código de confirmación" className="mb-3">
                                            <Form.Control
                                                isInvalid={this.state.verification_code.hasError}
                                                type="text"
                                                disabled={this.loading}
                                                placeholder={this.state.email.value}
                                                maxLength={6}
                                                onChange={e => { this.setField('verification_code', e.target.value); }}
                                                onBlur={e => { this.validateField('verification_code'); }} />
                                            <Form.Control.Feedback type="invalid">{this.state.verification_code.error}</Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Form.Group>
                                    <div className="d-grid gap-2">
                                        <BlueButton variant="primary" disabled={this.loading} type="submit" size="lg" onClick={() => {
                                            this.sendLoginConfirmation();
                                        }}>
                                            {this.loading ? 'Enviando' : 'Confirmar'}
                                        </BlueButton>

                                    </div>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </ContainerAutoHeight>
        );

        const recuperar_contrasena = (
            <ContainerAutoHeight>
                <Row className="justify-content-md-center">
                    <Col>
                        <h1 className="text-center">&nbsp;</h1>
                    </Col>
                </Row>
                <Row className="justify-content-md-center">
                    <Col lg={5} sm={12}>
                        <Card className="m1" style={card}>
                            <Card.Title className="mt-3 mb-3">
                                <h2 className="text-center">Recuperar</h2>
                                <h1 className="text-center" style={{color: colors.blue2}}>contraseña</h1>
                            </Card.Title>
                            <Card.Body>
                                <p>
                                    Para recuperar tu contraseña, necesitamos tu dirección de correo electrónico
                                </p>
                                <Form>
                                    <Form.Group className="mb-3" controlId="FormCorreo">
                                        <FloatingLabel controlId="Correo" label="Correo" className="mb-3">
                                            <Form.Control
                                                isInvalid={this.state.email.hasError}
                                                type="email"
                                                disabled={this.loading}
                                                placeholder="nombre@ejemplo.com"
                                                onChange={e => { this.setField('email', e.target.value); }}
                                                onBlur={e => { this.validateField('email'); }} />
                                            <Form.Control.Feedback type="invalid">{this.state.email.error}</Form.Control.Feedback>
                                        </FloatingLabel>
                                    </Form.Group>
                                    <div className="d-grid gap-2">
                                        <BlueButton variant="primary" disabled={this.loading} type="button" size="lg" onClick={() => {
                                            this.solicitarRecuperacion(this.state.email);
                                        }}>
                                            {this.loading ? 'Enviando' : 'Recuperar contraseña'}
                                        </BlueButton>
                                    </div>
                                    <div className="d-grid gap-2" style={{marginTop: '15px'}}>
                                        <WhiteButton variant="primary" disabled={this.loading} type="button" size="lg" onClick={() => {
                                            this.setState({
                                                ...this.state,
                                                step: 1,
                                            });
                                        }}>
                                            {this.loading ? 'Enviando' : 'Regresar'}
                                        </WhiteButton>
                                    </div>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </ContainerAutoHeight>
        );

        const recuperar_contrasena_end = (
            <ContainerAutoHeight>
                <Row className="justify-content-md-center">
                    <Col>
                        <h1 className="text-center">&nbsp;</h1>
                    </Col>
                </Row>
                <Row className="justify-content-md-center">
                    <Col lg={5} sm={12}>
                        <Card className="m1" style={card}>
                            <Card.Title className="mt-3 mb-3">
                                <h2 className="text-center">Recuperar contraseña</h2>
                            </Card.Title>
                            <Card.Body>
                                <p>
                                    Hemos enviado un correo a la dirección que nos proporcionaste con instrucciones para recuperar tu contraseña.
                                </p>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </ContainerAutoHeight>
        );

        return (<>
            <Header />
            {this.state.redirect}
            {this.state.loading ? loadingOverlay() : null}
            {this.state.step === 1 ? login_form : null}
            {this.state.step === 2 ? confirm_login_form : null}
            {this.state.step === 3 ? recuperar_contrasena : null}
            {this.state.step === 4 ? recuperar_contrasena_end : null}
            {NotificationContainer}
            <Footer />
        </>);
    }

}



export default Login;