import { useState } from 'react';
import {
    Col,
    Container,
    Row
} from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faEdit,
    faCog,
    faIdCard,
    faDoorOpen,
    faHouse
} from '@fortawesome/free-solid-svg-icons';

import colors from './colors';

import { Navigate } from 'react-router-dom';

import logo from '../assets/vector/logo.svg';
import jwt_decode from 'jwt-decode';
import cookieman from '../common/cookieman';


export default (props) => {
    let session = null;

    if (cookieman.checkItem('token') !== null) {
        session = jwt_decode(cookieman.getItem('token'));
    }

    const [redirect, setRedirect] = useState(null);

    const menu_element = {
        color: '#042994',
        textDecoration: 'none'
    };
    const footer_element = {
        color: '#fff !important'
    };

    const footer_link = {
        ...footer_element,
        textDecoration: 'none',
        padding: 0,
        color: '#fff'
    }

    const footer_button = {
        ...footer_element,
        textDecoration: 'none',
        padding: 0,
        color: colors.blue2
    }

    const style = {
        backgroundColor: "#F8F8F8",
        borderTop: "1px solid #E7E7E7",
        textAlign: "center",
        padding: "20px",
        position: "fixed",
        left: "0",
        bottom: "0",
        height: "60px",
        width: "100%",
        zIndex: 999
    }

    const phantom = {
        display: 'block',
        padding: '20px',
        height: '60px',
        width: '100%',
    }

    return (
        <>
            {redirect}
            <Col className="d-none d-md-block d-lg-block" md={3} lg={2} style={{
                backgroundColor: colors.grey3
            }}>
                <div style={{ padding: '1rem' }}>
                    <a href="/home"><img className="w-100" src={logo} /></a>
                </div>
                <ul style={{
                    listStyleType: 'none',
                    margin: 0,
                    padding: '15px',
                    overflow: 'hidden',
                }}>
                    <li>
                        <a href="/home" style={menu_element}>
                            <FontAwesomeIcon icon={faHouse} /> Inicio
                        </a>
                    </li>
                    <li>
                        <hr />
                    </li>
                    <li>
                        <a href="/membresias" style={menu_element}>
                            <FontAwesomeIcon icon={faIdCard} /> Membresías activas
                        </a>
                    </li>
                    <li>
                        <hr />
                    </li>
                    <li>
                        <a href="/membresia/nuevo" style={menu_element}>
                            <FontAwesomeIcon icon={faEdit} /> Crear membresía
                        </a>
                    </li>
                    <li>
                        <hr />
                    </li>
                    <li>
                        <a href="/configuracion" style={menu_element}>
                            <FontAwesomeIcon icon={faCog} /> Tu negocio
                        </a>
                    </li>
                    <hr />
                    <li>
                        <a href="#" style={menu_element} onClick={(e) => {
                            e.preventDefault();
                            setRedirect(<Navigate to="/logout" />);
                        }}>
                            <FontAwesomeIcon icon={faDoorOpen} /> Salir
                        </a>
                    </li>
                </ul>
            </Col>
            <div className="d-block d-md-none d-lg-none">
                <Container style={style}>
                    <Row>
                        <Col className="text-center active">
                            <a href="/home" className="btn btn-flat" style={footer_button}>
                                <FontAwesomeIcon icon={faHouse} />
                            </a>
                        </Col>
                        <Col className="text-center active">
                            <a href="/membresias" className="btn btn-flat" style={footer_button}>
                                <FontAwesomeIcon icon={faIdCard} />
                            </a>
                        </Col>
                        <Col className="text-center">
                            <a href="/membresia/nuevo" className="btn btn-flat" style={footer_button}>
                                <FontAwesomeIcon icon={faEdit} />
                            </a>
                        </Col>

                        <Col className="text-center">
                            <a href="/configuracion" className="btn btn-flat" style={footer_button}>
                                <FontAwesomeIcon icon={faCog} />
                            </a>
                        </Col>
                        <Col className="text-center">
                            <a href="#" className="btn btn-flat" style={footer_button} onClick={(e) => {
                                e.preventDefault();
                                setRedirect(<Navigate to="/logout" />);
                            }}>
                                <FontAwesomeIcon icon={faDoorOpen} />
                            </a>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};