import React, { useState, useEffect } from 'react';
import adapter from 'webrtc-adapter';


import {
    Row,
    Col,
    Spinner,
    Button,
    Modal
} from 'react-bootstrap';


import Header from '../Layout/simple/header';
import Footer from '../Layout/default/footer';
import Menu from '../common/Menu';
import ContainerAutoHeight from '../common/ContainerAutoHeight';

import { NotificationManager, NotificationContainer } from 'react-notifications';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faTrashCan } from '@fortawesome/free-solid-svg-icons';

import { Navigate, Link } from 'react-router-dom';
import colors from '../common/colors';

import cookieman from '../common/cookieman';

import axios from 'axios';
import config from '../../config/core';



export default (props) => {

    const [membresias, setMembresias] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);
    const [membresiaTarget, setMembresia] = useState();

    useEffect(() => {
        const fetchMembresias = async () => {
            const url = process.env.REACT_APP_BACKEND + '/membresias';

            const response = await axios.get(url, {
                headers: {
                    token: cookieman.getItem('token')
                }
            });

            setMembresias(response.data.data);
        }

        fetchMembresias().catch(error => {
            NotificationManager.error(error.response.msg, 'Error');
        });
    }, []);


    const eliminarMembresia = (uuid) => {


        const url = process.env.REACT_APP_BACKEND + '/membresias/' + uuid;
        axios.delete(url, {
            headers: {
                token: cookieman.getItem('token')
            }
        }).then(async response => {
            NotificationManager.success('Membresía eliminada', 'Éxito');
            const url2 = process.env.REACT_APP_BACKEND + '/membresias';

            const response2 = await axios.get(url2, {
                headers: {
                    token: cookieman.getItem('token')
                }
            });

            setMembresias(response2.data.data);
        }).catch(error => {
            NotificationManager.error(error.response.msg, 'Error');

        }).finally(() => {

        });
    }

    const rows = [];
    const rows_xs = [];

    const actionStyle = {
        margin: '5px'
    };

    if (Array.isArray(membresias) && membresias.length) {
        membresias.forEach((membresia, i) => {
            rows.push((
                <tr>
                    <td>{membresia.membresia}</td>
                    <td>{membresia.nombre} {membresia.primer_apellido} {membresia.segundo_apellido}</td>
                    <td className='text-center'>${membresia.precio}</td>
                    <td className='text-center'>{membresia.puntos}</td>
                    <td className="text-center">{membresia.tasa_bonificacion}%</td>
                    <td className='text-center'>
                        <Link to={'/membresia/' + membresia.uuid} style={actionStyle}>
                            <FontAwesomeIcon icon={faEye} />
                        </Link>
                        <Button variant="danger" onClick={() => {
                            setMembresia(membresia);
                            setShowConfirm(true);
                        }}>
                            <FontAwesomeIcon icon={faTrashCan} />
                        </Button>
                    </td>
                </tr>
            ));

            rows_xs.push((
                <tr>
                    <td>{membresia.membresia}</td>
                    <td>{membresia.nombre} {membresia.primer_apellido} {membresia.segundo_apellido}</td>
                    <td className='text-center'>{membresia.puntos}</td>
                    <td className='text-center'>
                        <Link to={'/membresia/' + membresia.uuid} style={actionStyle}>
                            <FontAwesomeIcon icon={faEye} />
                        </Link>
                        <Button variant="danger" onClick={() => {
                            setMembresia(membresia);
                            setShowConfirm(true);
                        }}>
                            <FontAwesomeIcon icon={faTrashCan} />
                        </Button>
                    </td>
                </tr>
            ));
        });
    }

    return (
        <>
            <Header mustHaveSession />
            <ContainerAutoHeight fluid>
                <Row>
                    <Menu />
                    <Col>
                        <p className="text-center">
                            <span style={{ fontSize: '24px' }}>Membresías</span>
                            <br />
                            <span style={{ fontSize: '28px', color: colors.blue2 }}>Creadas</span>
                        </p>
                        {
                            loading ?
                                <Spinner animation="border" size="xl" />
                                :
                                <>
                                    <div className="d-none d-sm-block">
                                        <table className='table'>
                                            <thead>
                                                <tr>
                                                    <td>Membresía</td>
                                                    <td>Cliente</td>
                                                    <td className="text-center">Precio</td>
                                                    <td className='text-center'>Puntos</td>
                                                    <td className="text-center">% bonificación</td>
                                                    <td className='text-center'>Acciones</td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {rows}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="d-sm-none">
                                        <table className='table'>
                                            <thead>
                                                <tr>
                                                    <td>Membresía</td>
                                                    <td>Cliente</td>
                                                    <td className='text-center'>Puntos</td>
                                                    <td className='text-center'>Acciones</td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {rows_xs}
                                            </tbody>
                                        </table>
                                    </div>
                                </>
                        }
                    </Col>
                </Row>
            </ContainerAutoHeight>
            <Modal show={showConfirm} onHide={() => { setShowConfirm(false); }}>
                <Modal.Header closeButton>
                    <Modal.Title>Eliminar membresía</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    ¿Estás seguro de querer borrar esta membresía?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => {
                        eliminarMembresia(membresiaTarget.uuid);
                        setShowConfirm(false);
                    }}>Aceptar</Button>
                    <Button variant="info" onClick={() => { setShowConfirm(false); }}>Cancelar</Button>
                </Modal.Footer>
            </Modal>
            <Footer />
            <NotificationContainer />
        </>
    );
}