import React, { Component } from 'react';

import {
    Row,
    Col,
    Form,
    FloatingLabel,
    Modal,
    Button,
    Dropdown
} from 'react-bootstrap';

import OrangeButton from '../common/Form/OrangeButton';
import BlueButton from '../common/Form/OrangeButton';

import { NotificationContainer, NotificationManager } from 'react-notifications';

import axios from 'axios';
import validate from 'validate.js';

import config from '../../config/core';
import cookieman from '../common/cookieman';

import Header from '../Layout/simple/header';
import Footer from '../Layout/default/footer';
import ContainerAutoHeight from '../common/ContainerAutoHeight';

import Menu from '../common/Menu';

import card_icon from '../assets/card-icon.svg';

import loadingOverlay from '../common/loadingOverlay';
import colors from '../common/colors';

class Home extends Component {
    state = {
        loading: true,
        loading_modal: false,
        usuario: null,
        showEmitirMembresiaViaEmailModal: false,
        showInvitarClienteModal: false,
        membresias: [],
        // 
        nombre: {
            value: '',
            hasError: false,
            error: ''
        },
        primer_apellido: {
            value: '',
            hasError: false,
            error: ''
        },
        segundo_apellido: {
            value: '',
            hasError: false,
            error: ''
        },
        correo: {
            value: '',
            hasError: false,
            error: ''
        },
        telefono: {
            value: '',
            hasError: false,
            error: ''
        },
        membresia_seleccionada: {
            value: '',
            hasError: false,
            error: ''
        },
    };

    emisionCorreoConstraints = {
        correo: {
            presence: {
                allowEmpty: false,
                message: 'es requerido'
            },
            email: {
                message: 'no es una dirección válida'
            }
        },
        membresia: {
            presence: {
                allowEmpty: false,
                message: 'es requerido'
            },
        }
    }

    formConstraints = {
        nombre: {
            presence: {
                allowEmpty: false,
                message: 'es requerido'
            },
            format: {
                pattern: "[a-zA-ZÁÉÍÓÚáéíóúñÑ ]*",
                message: "solo puede contener letras"
            }
        },
        primer_apellido: {
            presence: {
                allowEmpty: false,
                message: 'es requerido'
            },
            format: {
                pattern: "[a-zA-ZÁÉÍÓÚáéíóúñÑ ]*",
                message: "solo puede contener letras"
            }
        },
        segundo_apellido: {
            presence: {
                allowEmpty: false,
                message: 'es requerido'
            },
            format: {
                allowEmpty: false,
                pattern: "[a-zA-ZÁÉÍÓÚáéíóúñÑ ]*",
                message: "solo puede contener letras"
            }
        },
        correo: {
            presence: {
                allowEmpty: false,
                message: 'es requerido'
            },
            email: true
        },
        telefono: {
            presence: {
                allowEmpty: false,
                message: 'es requerido'
            },
            format: {
                pattern: "[0-9]*",
                message: "solo puede contener números"
            }
        },
    };


    componentDidMount() {
        const url_membresias = process.env.REACT_APP_BACKEND + '/catmembresias';

        axios.get(url_membresias, {
            headers: {
                token: cookieman.getItem('token')
            }
        }).then(response => {
            if (response.data.success) {
                //console.log(response.data.data);
                this.setState({
                    ...this.state,
                    membresias: response.data.data,
                });
            }
            else {

            }
        }).catch(error => {

        }).finally(() => {
            this.setState({
                ...this.state,
                loading: false
            });
        });

    }


    onEmitirViaEmail(visible, membresia_nombre, membresia_slug) {
        //this.setShowEmitirViaEmailModal(true);
        this.setState({
            ...this.state,
            showEmitirMembresiaViaEmailModal: visible,
            membresia_seleccionada: {
                nombre: membresia_nombre,
                slug: membresia_slug
            }
        });
    }

    setShowEmitirViaEmailModal(visible) {
        this.setState({
            ...this.state,
            showEmitirMembresiaViaEmailModal: visible
        });
    }

    setShowInvitarClienteModal = (visible) => {
        this.setState({
            ...this.state,
            showInvitarClienteModal: visible,
        });
    }

    emitirMembresiaViaEmail = () => {
        const emision_via_email = {
            correo: this.state.correo.value,
            membresia: this.state.membresia_seleccionada.slug
        }

        const validacion = validate(emision_via_email, this.emisionCorreoConstraints);

        if (typeof validacion !== 'undefined') {
            // notificar errores
            const newState = { ...this.state };

            Object.keys(validacion).forEach(field => {
                newState[field].hasError = true;
                newState[field].error = validacion[field][0];
            });

            this.setState(newState);

            return false;
        }

        // enviar membresía por correo
        const url = `${process.env.REACT_APP_BACKEND}/catmembresias/emitir_email/` + this.state.membresia_seleccionada.slug;

        this.setState({
            ...this.state,
            loading_modal: true
        });

        axios.post(url, {
            "correo": this.state.correo.value,
        }, {
            headers: {
                Token: cookieman.getItem('token')
            }
        }).then(result => {
            if (result.data.success) {
                // limpiar formulario
                this.setField('correo', '');

                // cerrar modal
                this.setShowEmitirViaEmailModal(false);

                NotificationManager.success(result.data.msg, 'Éxito!');
            }
            else {
                NotificationManager.error(result.data.msg, 'Error');
            }
        }).catch(error => {
            NotificationManager.error(error, 'Error');
        }).finally(_ => {
            this.setState({
                ...this.state,
                loading: false
            });
        });
    }

    validateField = (field) => {
        const constraint = {};

        constraint[field] = this.emisionCorreoConstraints[field];

        const data = {};

        data[field] = this.state[field].value;

        if (typeof this.emisionCorreoConstraints[field].equality !== 'undefined') {
            data[this.emisionCorreoConstraints[field].equality] = this.state[this.emisionCorreoConstraints[field].equality].value;
        }

        const validacion = validate(data, constraint);

        const newState = { ...this.state };

        if (typeof validacion !== 'undefined') {
            newState[field].hasError = true;
            newState[field].error = (
                <ul>
                    {
                        validacion[field].map(error => {
                            return (<li>{error}</li>);
                        })
                    }
                </ul>
            );
        }
        else {
            newState[field].hasError = false;
            newState[field].error = null;

        }
        this.setState(newState);
    }
    
    validateInvitacionField = (field) => {
        const constraint = {};

        constraint[field] = this.formConstraints[field];

        const data = {};

        data[field] = this.state[field].value;

        if (typeof this.formConstraints[field].equality !== 'undefined') {
            data[this.formConstraints[field].equality] = this.state[this.formConstraints[field].equality].value;
        }

        const validacion = validate(data, constraint);

        const newState = { ...this.state };

        if (typeof validacion !== 'undefined') {
            newState[field].hasError = true;
            newState[field].error = (
                <ul>
                    {
                        validacion[field].map(error => {
                            return (<li>{error}</li>);
                        })
                    }
                </ul>
            );
        }
        else {
            newState[field].hasError = false;
            newState[field].error = null;

        }
        this.setState(newState);
    }

    setField(field, value) {
        const newState = { ...this.state };

        if (typeof newState[field].value !== 'undefined') {
            newState[field].value = value;

            this.setState(newState);
        }
    }

    setError(field, msg) {
        const newState = { ...this.state };

        if (typeof newState[field].error !== 'undefined') {
            newState[field].hasError = true;
            newState[field].error = msg;

            this.setState(newState);
        }
    }

    freeError(field) {
        const newState = { ...this.state };

        if (typeof newState[field].hasError !== 'undefined') {
            newState[field].hasError = false;

            this.setState(newState);
        }
    }

    invitarCliente = () => {
        // validar formulario
        const invitacion = {
            nombre: this.state.nombre.value,
            primer_apellido: this.state.primer_apellido.value,
            segundo_apellido: this.state.segundo_apellido.value,
            correo: this.state.correo.value,
            telefono: this.state.telefono.value
        };

        const validacion = validate(invitacion, this.formConstraints);

        if (typeof validacion !== 'undefined') {
            // notificar errores
            const newState = { ...this.state };

            Object.keys(validacion).forEach(field => {
                newState[field].hasError = true;
                newState[field].error = validacion[field][0];
            });

            this.setState(newState);

            return false;
        }

        // enviar invitación
        const url = `${process.env.REACT_APP_BACKEND}/usuarios/invitar_cliente`;
        axios.post(url, {
            "nombre": this.state.nombre.value,
            "primer_apellido": this.state.primer_apellido.value,
            "segundo_apellido": this.state.segundo_apellido.value,
            "correo": this.state.correo.value,
            "telefono": this.state.telefono.value
        }, {
            headers: {
                Token: cookieman.getItem('token')
            }
        }).then(result => {
            if (result.data.success) {
                // limpiar formulario
                this.setField('nombre', '');
                this.setField('primer_apellido', '');
                this.setField('segundo_apellido', '');
                this.setField('correo', '');
                this.setField('telefono', '');

                // cerrar modal
                this.setShowInvitarClienteModal(false);

                NotificationManager.success('Tu cliente ha sido invitado.', 'Éxito!');
            }
            else {
                NotificationManager.error(result.data.msg, 'Error');
            }
        }).catch(error => {
            NotificationManager.error(error, 'Error');
        });
    }

    render() {

        if (this.state.loading) {
            return (loadingOverlay());
        }

        const membresias_cards = [];
        var hr = null;
        this.state.membresias.forEach(membresia => {
            membresias_cards.push(
                <div key={Math.random()}>
                    {hr}
                    < Row  className="d-flex align-items-center" style={{ padding: 0 }} >
                        <Col xs={4} sm={4} md={3} lg={3}>
                            <img src={card_icon} alt="card" />
                        </Col>
                        <Col xs={6} sm={6} md={7} lg={7}>
                            <div style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>{membresia.nombre}</div>
                            <span>{membresia.activas} activas<br />{membresia.inactivas} inactivas</span>
                        </Col>
                        <Col xs={2} sm={2} style={{ padding: 0 }}>
                            <Dropdown className="float-end" >
                                <Dropdown.Toggle variant="flat" />
                                <Dropdown.Menu>
                                    <Dropdown.Item href={`membresia/${membresia.slug}/editar`}>Editar</Dropdown.Item>
                                    <Dropdown.Item href={`membresia/${membresia.slug}/deshabilitar`}>Deshabilitar</Dropdown.Item>
                                    <Dropdown.Divider />
                                    <Dropdown.Item href="#" onClick={() => this.onEmitirViaEmail(true, membresia.nombre, membresia.slug)}>Emitir por correo</Dropdown.Item>
                                    <Dropdown.Item href={"membresia/emitir/qr/" + membresia.slug + "/" + membresia.nombre}>Emitir con QR</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col>
                    </Row >
                </div>
            );

            hr = (<hr key={Math.random()} style={{ margin: 0 }} />);
        });

        const styles = {
            membresias_counts: {
                fontSize: '96px',
                color: colors.blue2,
                lineHeight: '98px',
                margin: '0 auto',
                //marginLeft: '25%',
            },
            membresias_label: {
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
            }
        };

        return (
            <>
                <Header mustHaveSession />
                <ContainerAutoHeight fluid>
                    <Row className="justify-content-md-center">
                        <Menu active="" />
                        <Col style={{
                            paddingLeft: '3rem',
                            paddingRight: '3rem',
                        }}>
                            <Row className="justify-content-md-center" style={{ paddingTop: '2rem' }}>
                                <Col className="text-center">
                                    <p>
                                        <span style={{ fontSize: '24px' }}>Membresías</span>
                                        <br />
                                        <span style={{ fontSize: '28px', color: colors.blue2 }}>Activas</span>
                                    </p>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="text-center">
                                    <OrangeButton onClick={() => this.setShowInvitarClienteModal(true)}>Invitar a un cliente</OrangeButton>
                                </Col>
                            </Row>
                            <Row className="d-none d-md-flex d-lg-flex">
                                <Col xs={4} sm={4} md={4} lg={4} className="d-flex align-items-center">
                                    <span style={styles.membresias_counts}>{this.state.membresias.length}</span>
                                    <span style={styles.membresias_label}>plantillas creadas</span>
                                </Col>
                                <Col xs={4} sm={4} md={4} lg={4} className="d-flex align-items-center">
                                    <span style={styles.membresias_counts}>{this.state.membresias.reduce((partial, m) => partial + m.activas, 0)}</span>
                                    <span style={styles.membresias_label}>membresías activas</span>
                                </Col>
                                <Col xs={4} sm={4} md={4} lg={4} className="d-flex align-items-center">
                                    <span style={{ ...styles.membresias_counts, color: colors.orange }}>{this.state.membresias.reduce((partial, m) => partial + m.inactivas, 0)}</span>
                                    <span style={styles.membresias_label}>membresías inactivas</span>
                                </Col>
                            </Row>
                            <Row className="d-flex d-md-none d-lg-none">
                                <Col xs={4} sm={4} md={4} lg={4} className="text-center">
                                    <span style={styles.membresias_counts}>{this.state.membresias.length}</span>
                                    <p style={styles.membresias_label}>plantillas <br />creadas</p>
                                </Col>
                                <Col xs={4} sm={4} md={4} lg={4} className="text-center">
                                    <span style={styles.membresias_counts}>{this.state.membresias.reduce((partial, m) => partial + m.activas, 0)}</span>
                                    <p style={styles.membresias_label}>membresías <br />activas</p>
                                </Col>
                                <Col xs={4} sm={4} md={4} lg={4} className="text-center">
                                    <span style={{ ...styles.membresias_counts, color: colors.orange }}>{this.state.membresias.reduce((partial, m) => partial + m.inactivas, 0)}</span>
                                    <p style={styles.membresias_label}>membresías <br />inactivas</p>
                                </Col>
                            </Row>
                            <Row style={{
                                backgroundColor: colors.grey3,
                                paddingLeft: '1rem',
                                marginTop: '1rem'
                            }}>
                                {membresias_cards}
                            </Row>
                        </Col>
                    </Row>
                </ContainerAutoHeight>
                <Modal show={this.state.showEmitirMembresiaViaEmailModal} onHide={() => {
                    this.setShowEmitirViaEmailModal(false);
                }}>
                    <Modal.Header closeButton>
                        <Modal.Title>Emitir "{this.state.membresia_seleccionada.nombre}" vía Email</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form action="home" onSubmit={(e) => {
                            e.preventDefault();
                            this.emitirMembresiaViaEmail();
                        }}>
                            <FloatingLabel
                                controlId="ClienteCorreo"
                                label="¿A quién se la vamos a enviar?"
                                className="mb-3"
                            >
                                <Form.Control
                                    isInvalid={this.state.correo.hasError}
                                    type="email"
                                    placeholder="Correo electrónico"
                                    value={this.state.correo.value}
                                    onChange={e => { this.setField('correo', e.target.value); }}
                                    onBlur={() => { this.validateField('correo') }}
                                    maxLength={50} />
                                <Form.Control.Feedback type="invalid">{this.state.correo.error}</Form.Control.Feedback>
                            </FloatingLabel>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" type="button" onClick={() => {
                            this.setShowEmitirViaEmailModal(false)
                        }} disabled={this.state.loading_modal}>Cerrar</Button>
                        <BlueButton variant="primary" type="submit" onClick={this.emitirMembresiaViaEmail} disabled={this.state.loading_modal}>Enviar membresía</BlueButton>
                    </Modal.Footer>
                </Modal>

                <Modal show={this.state.showInvitarClienteModal} onHide={() => {
                    this.setShowInvitarClienteModal(false);
                }}>
                    <Modal.Header closeButton>
                        <Modal.Title>Invita un cliente</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <Form>
                            <FloatingLabel
                                controlId="ClienteNombre"
                                label="Nombre"
                                className="mb-3"
                            >
                                <Form.Control
                                    isInvalid={this.state.nombre.hasError}
                                    type="text"
                                    placeholder="¿Cuál es el nombre(s) cliente?"
                                    value={this.state.nombre.value}
                                    onChange={e => { this.setField('nombre', e.target.value); }}
                                    onBlur={() => { this.validateInvitacionField('nombre') }}
                                    maxLength={50} />
                                <Form.Control.Feedback type="invalid">{this.state.nombre.error}</Form.Control.Feedback>
                            </FloatingLabel>
                            <FloatingLabel
                                controlId="ClientePrimerApellido"
                                label="Primer Apellido"
                                className="mb-3"
                            >
                                <Form.Control
                                    isInvalid={this.state.primer_apellido.hasError}
                                    type="text"
                                    placeholder="¿Cuál es su primer apellido?"
                                    value={this.state.primer_apellido.value}
                                    onChange={e => { this.setField('primer_apellido', e.target.value); }}
                                    onBlur={() => { this.validateInvitacionField('primer_apellido') }}
                                    maxLength={50} />
                                <Form.Control.Feedback type="invalid">{this.state.primer_apellido.error}</Form.Control.Feedback>
                            </FloatingLabel>
                            <FloatingLabel
                                controlId="ClienteSegundoApellido"
                                label="Segundo Apellido"
                                className="mb-3"
                            >
                                <Form.Control
                                    isInvalid={this.state.segundo_apellido.hasError}
                                    type="text"
                                    placeholder="¿Cuál es su segundo apellido?"
                                    value={this.state.segundo_apellido.value}
                                    onChange={e => { this.setField('segundo_apellido', e.target.value); }}
                                    onBlur={() => { this.validateInvitacionField('segundo_apellido') }}
                                    maxLength={50} />
                                <Form.Control.Feedback type="invalid">{this.state.segundo_apellido.error}</Form.Control.Feedback>
                            </FloatingLabel>
                            <FloatingLabel
                                controlId="ClienteCorreo"
                                label="Correo electrónico"
                                className="mb-3"
                            >
                                <Form.Control
                                    isInvalid={this.state.correo.hasError}
                                    type="email"
                                    placeholder="¿Cual es su dirección de correo?"
                                    value={this.state.correo.value}
                                    onChange={e => { this.setField('correo', e.target.value); }}
                                    onBlur={() => { this.validateInvitacionField('correo') }}
                                    maxLength={50} />
                                <Form.Control.Feedback type="invalid">{this.state.correo.error}</Form.Control.Feedback>
                            </FloatingLabel>
                            <FloatingLabel
                                controlId="ClienteTelefono"
                                label="Celular"
                                className="mb-3"
                            >
                                <Form.Control
                                    isInvalid={this.state.telefono.hasError}
                                    type="number"
                                    placeholder="¿Cual es su número de teléfono?"
                                    value={this.state.telefono.value}
                                    onBlur={() => { this.validateInvitacionField('telefono') }}
                                    onChange={e => {
                                        const telefono = e.target.value;

                                        if (telefono.length > 10) {
                                            return false;
                                        }

                                        this.setField('telefono', e.target.value);
                                    }} />
                                <Form.Control.Feedback type="invalid">{this.state.telefono.error}</Form.Control.Feedback>
                            </FloatingLabel>
                        </Form>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => this.setShowInvitarClienteModal(false)}>Cerrar</Button>
                        <Button variant="primary" onClick={this.invitarCliente}>Enviar invitación</Button>
                    </Modal.Footer>
                </Modal>
                <NotificationContainer />
                <Footer />
            </>
        );
    }
}

export default Home;