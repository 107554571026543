import React from 'react';
import './DragDropFile.css'
// drag drop file component
function DragDropFile(props) {
    const defaults = {
        onDragOver: () => { },
        onDragLeave: () => { },
        onDrop: () => { },
        onChange: () => { },
        handleFiles: (files) => { }
    }

    if (typeof props.handleFiles === 'function') {
        defaults.handleFiles = props.handleFiles;
    }

    // drag state
    const [dragActive, setDragActive] = React.useState(false);
    // ref
    const inputRef = React.useRef(null);

    // handle drag events
    const handleDrag = function (e) {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true);
            defaults.onDragOver();
        } else if (e.type === "dragleave") {
            setDragActive(false);
            defaults.onDragLeave();
        }

    };

    // triggers when file is dropped
    const handleDrop = function (e) {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            // handleFiles(e.dataTransfer.files);
            Object.keys(e.dataTransfer.files).forEach(i => {
                const file = e.dataTransfer.files[i];
                getBase64(file, defaults.handleFiles);
            });
        }
    };

    // triggers when file is selected with click
    const handleChange = function (e) {
        e.preventDefault();
        if (e.target.files && e.target.files[0]) {
            // handleFiles(e.target.files);
            Object.keys(e.target.files).forEach(i => {
                const file = e.target.files[i];
                getBase64(file, defaults.handleFiles);
            });
        }
    };

    // triggers the input when the button is clicked
    const onButtonClick = () => {
        inputRef.current.click();
    };

    const getBase64 = (file, cb) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb({
                name: file.name,
                type: file.type,
                size: file.size,
                base64: reader.result
            });
        };
        reader.onerror = function (error) {
            //console.log('Error: ', error);
        };
    }

    return (
        <form id="form-file-upload" onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()}>
            <input ref={inputRef} type="file" id="input-file-upload" multiple={true} onChange={handleChange} accept="image/*" />
            <label id="label-file-upload" htmlFor="input-file-upload" className={dragActive ? "drag-active" : ""}>
                <div>
                    <p>Selecciona y suelta tu archivo aquí o</p>
                    <button className="upload-button" onClick={onButtonClick}>Sube un archivo</button>
                </div>
            </label>
            {dragActive && <div id="drag-file-element" onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}></div>}
        </form>
    );
};

export default DragDropFile;