import Logo from '../../assets/vector/logo-white.svg';

import config from '../../../config/core';

import {
    Container,
    Row,
    Col,
    Button
} from 'react-bootstrap';


const footer_element = {
    color: '#fff !important'
};

const footer_link = {
    ...footer_element,
    textDecoration: 'none',
    padding: 0,
    color: '#fff'
}



const Footer = () => {
    return (
        <>
            <Container fluid style={{ background: '#222a42', marginTop: '15px', padding: '2rem' }}>
                <Row>
                    <Col sm={12} xs={12} md={3} lg={3}>
                        <a href="/"><img src={Logo} style={{ width: '50%', margin: '15px' }} /></a>
                    </Col>
                    <Col sm={12} xs={12} md={3} lg={3}>
                        <p style={footer_element}><a style={footer_link} href="#">Facebook</a></p>
                        <p style={footer_element}><a style={footer_link} href="#">Preguntas frecuentes</a></p>
                    </Col>
                    <Col sm={12} xs={12} md={3} lg={3}>
                        <p style={footer_element}><a style={footer_link} href="#">Contacto</a></p>
                        <p style={footer_element}><a style={footer_link} href="#">Instagram</a></p>
                        <p style={footer_element}><a style={footer_link} href="#">Facebook</a></p>
                    </Col>
                    <Col sm={12} xs={12} md={3} lg={3}>
                        <p style={footer_element}><a style={footer_link} href="/registro">Comienza gratis</a></p>
                        <p style={footer_element}><a style={footer_link} href="/login">Ingresa</a></p>
                        <p style={footer_element}><a style={footer_link} href={config.link_clientes}>Clientes</a></p>
                    </Col>
                </Row>
                <Row>
                    <Col className="text-center">
                        <a style={{ ...footer_link, textDecoration: 'none' }} target="_blank" href="/terminos-y-condiciones">Términos y Condiciones</a> <span style={{ ...footer_link, textDecoration: 'none' }}>|</span> <a style={{ ...footer_link, textDecoration: 'none' }}  target="_blank" href="/aviso-de-privacidad">Aviso de privacidad</a>
                    </Col>
                </Row>
            </Container>
            
        </>
    );
};

export default Footer;