import React from 'react'
import {
    Container,
    Row,
    Col,
    Card,
    Form,
    Button,
    FloatingLabel,
    Alert,
    Dropdown
} from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faList, faListDots, faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';

export default (props) => {

    const beneficios = [];

    if(typeof props.beneficios !== 'undefined' && props.beneficios.length > 0) {
        props.beneficios.forEach(beneficio => {
            beneficios.push(
                <li key={beneficio.id}>{beneficio.descripcion}</li>
            );
        });
    }
    else {
        beneficios.push(<li>No hay beneficios todavía. Agrega uno!!!</li>);
    }

    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <div
            ref={ref}
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
            }}
            style={{ width: '30px', margin: '30px' }}
        >
            {children}

        </div>
    ));
            
    return (
        <Col style={{ marginTop: '15px' }}>
            <Card style={{
                height: "450px"
            }}>
                <Card.Title className="text-center">
                    <h1>{props.titulo}<br />${props.precio}</h1>
                </Card.Title>
                <Card.Body className="d-flex flex-column" style={{ overflow: 'hidden' }}>
                    <div style={{ height: '300px', overflowY: 'scroll' }}>
                        <p>{props.descripcion}</p>
                        <p>Beneficios</p>
                        <ul>
                            {beneficios}
                        </ul>
                        {props.aclaraciones !== null ? (<div dangerouslySetInnerHTML={{ __html: props.aclaraciones }}></div>) : null}
                    </div>
                </Card.Body>
            </Card>
        </Col>
    );
}