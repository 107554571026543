import cookieman from './cookieman';
import axios from 'axios';

import config from '../../config/core';

export default async () => {
    const url = process.env.REACT_APP_BACKEND + '/usuarios/is_valid_session';

    let isValid = true;

    const response = await axios({
        url: url,
        type: 'get',
        headers: {
            token: cookieman.getItem('token')
        },
    }).catch(error => {
        //cookieman.deleteItem('token');
        
        isValid = false;
    });
    
    return isValid;
}